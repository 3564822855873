<template>
  <publicImport :importTypeList="importTypeList"></publicImport>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import publicImport from "@/components/common/publicImport";

export default {
  name: "importTask",
  data() {
    return {
      importTypeList: [
        {value: 'productImport', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000685')},
        {value: 'inventoryImport', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000717')},
        {value: 'productGoodsSuggestPriceImport', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000749')},
        {value: 'productGoodsStatusImport', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005626')},
        {value: 'importSupplierStockOrder', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1004484')},
      ]
    };
  },
  components: {
    publicImport
  }
};
</script>
<style scoped>

</style>
